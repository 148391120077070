<template>
  <div class="clock-role-container">
    <div class="title">考勤规则</div>
    <div class="base-info">
      <img :src="userInfo.avatarUrl || defaultAvatar" alt="" class="avatar" />
      <div class="right">
        <div class="name">{{ userInfo.userName }}</div>
        <div class="department">
          <span class="departmentName">{{ userInfo.departmentName }}</span>
        </div>
      </div>
    </div>
    <van-cell
      title="考勤时间"
      is-link
      :arrow-direction="timeDes ? 'up' : 'down'"
      value=""
      @click="timeDes = !timeDes"
    />
    <div class="card" v-if="timeDes">
      <p class="card-title">上下班时间</p>
      <p
        class="card-text"
        v-for="(item, index) in clockRule.clockRule"
        :key="index"
      >
        {{ item }}
      </p>
    </div>
    <van-cell
      title="考勤范围"
      is-link
      :arrow-direction="rangeDes ? 'up' : 'down'"
      value=""
      @click="rangeDes = !rangeDes"
    />
    <div class="card" v-if="rangeDes">
      <p class="card-title">办公地点</p>
      <p class="card-text blue" @click="goAmap">
        <van-icon name="location-o" />
        <span>{{ clockRule.address }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import { getMyStatffInfo } from "@/api/staff/index.js";
import { getClockRule } from "@/api/clock/index.js";
import defaultAvatar from "@/assets/images/home/header.png";

import Cookies from "js-cookie";
export default {
  name: "clockRole",
  props: {},
  components: {},
  data() {
    return {
      userInfo: {},
      timeDes: false,
      rangeDes: false,
      clockRule: {},
      defaultAvatar,
    };
  },
  watch: {},
  mounted() {
    this.getStaffInfo();
    this.getRule();
  },
  filters: {},
  computed: {},
  methods: {
    getStaffInfo() {
      getMyStatffInfo().then((res) => {
        this.userInfo = res.data;
      });
    },

    getRule() {
      getClockRule().then((res) => {
        this.clockRule = res.data;
      });
    },
    goAmap() {
      this.$router.push({
        path: "/AMap",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.clock-role-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #f3f6f6;
  padding-bottom: 60px;
  .title {
    background: #fff;
    height: 0.9rem;
    font-size: 0.38rem;
    font-weight: bold;
    line-height: 0.9rem;
    padding: 0 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
  .base-info {
    padding: 0 0.3rem;
    background: #fff;
    display: flex;
    height: 1.44rem;
    align-items: center;
    .avatar {
      width: 0.8rem;
      height: 0.8rem;
      margin-right: 0.3rem;
      border-radius: 0.12rem;
    }
    .right {
      flex: 1;
      width: 1rem;
      .name {
        font-size: 0.34rem;
        margin-bottom: 0.18rem;
        font-size: 0.34rem;
      }
      .department {
        font-size: 0.28rem;
        color: #666;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .card {
    padding: 0.3rem;
    margin-bottom: 0.35rem;
    background: #fff;
    .card-title {
      font-weight: bold;
      margin-bottom: 0.2rem;
    }
    .card-text {
      color: #999;
      font-size: 0.24rem;
      line-height: 0.38rem;
    }
    .card-text.blue {
      color: #0094ee;
    }
  }
}
</style>
